/**
 * @file changelog.jsx
 * @description Changelog page.
 * @author Felix Waßmuth (Felix | D1strict)
 * @license Exclusive property of Felix Waßmuth (Felix | D1strict)
 * @version 0.1.0
 * @since 0.1.0
 * @copyright Felix Waßmuth (Felix | D1strict), 2023
 */

import React from "react";
import Meta from "../components/Meta";
import Hero from "../components/hero";

/**
 * @function ChangelogPage
 * @description Changelog page.
 * @returns {JSX.Element} Changelog page.
 */

function ChangelogPage() {
  return (
    <>
      <Meta
        title="Changelog"
        description="Get the latest news about Media-URL."
      />
      <Hero title="Changelog" subtitle="Get the latest news about Media-URL." />
      <div className="section">
        <div className="container">
          <div className="timeline is-centered">
            <header className="timeline-header">
              <span className="tag is-medium is-primary">Version 1.0.0</span>
            </header>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangelogPage;
